import ExportHero from './Partials/ExportHero';
import ExportSection from './Partials/ExportSection';
import ExportDay from './Partials/ExportDay';
import ExportDownloadButton from './Partials/ExportDownloadButton';
import ExportGeneralSummary from './Partials/ExportGeneralSummary';
import ExportItinerarySummary from './Partials/ExportItinerarySummary';
import ExportDayOptions from './Partials/ExportDayOptions';
import htmlToPdf, { prepareDocumentForHtmlToPdf } from '@koob/html-to-pdf';
import { useState } from 'react';

export default function TripExport({ trip, countries, tourOperator, experiences, images }) {
  const [processing, setProcessing] = useState(false);

  const exportToPdf = async () => {
    setProcessing(true);

    const html = prepareDocumentForHtmlToPdf(document.cloneNode(true));

    await htmlToPdf({
      html,
      options: {
        scale: 0.8,
        landscape: true,
        margin: {
          top: '0cm',
          bottom: '0cm',
          left: '0cm',
          right: '0cm',
        },
      },
      filename: `travelogue-${trip?.id}-${trip?.name?.replace(/\s+/g, '-')?.toLowerCase()}`
    });

    setProcessing(false);
  }

  const itineraryPages = Math.max(
    Math.ceil((trip?.tripDays?.length ?? 1) / 9)
    , 1
  );

  return (
    <div
      id="export-target"
      className="bg-white"
    >
      <div className="export-hidden fixed bottom-16 right-5 z-10">
        <ExportDownloadButton
          onClick={() => exportToPdf()}
          processing={processing}
        />
      </div>

      <ExportHero
        trip={trip}
        countries={countries}
        tourOperator={tourOperator}
        images={images}
      />

      <ExportSection
        title="General Summary"
        trip={trip}
        tourOperator={tourOperator}
      >
        <div className="h-full max-w-4xl mx-auto">
          <ExportGeneralSummary
            trip={trip}
            tourOperator={tourOperator}
          />
        </div>
      </ExportSection>

      {[...Array(itineraryPages)].map((_, index) => (
        <ExportSection
          key={index}
          title={`Itinerary Summary ${itineraryPages > 1 ? `(${index + 1}/${itineraryPages})` : '' }`}
          trip={trip}
          tourOperator={tourOperator}
        >
          <ExportItinerarySummary
            trip={trip}
            experiences={experiences}
            page={index + 1}
            perPage={9}
          />
        </ExportSection>
      ))}

      {trip?.tripDays?.map((day, index) => (
        <ExportDay
          key={index}
          trip={trip}
          tourOperator={tourOperator}
          day={{...day, experienceDayIndex: index}}
          index={index}
          experience={experiences.find(
            experience => experience.id === day.experienceId,
          )}
        />
      ))}

      {(trip?.globalOptions?.length > 0) && (
        <ExportSection
          title="Global Options"
          trip={trip}
          tourOperator={tourOperator}
        >
          <ExportDayOptions
            options={trip.globalOptions}
            mode="global"
          />
        </ExportSection>
      )}
    </div>
  );
}
