import { Form, Formik } from 'formik';

export default function UploadDocument({
  children,
  loaderClass,
  onSubmit,
  processing,
  initialesValues
}) {

  return (
    <Formik
      initialValues={initialesValues}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, submitForm }) => (
        <Form>
          <label className="appearance-none cursor-pointer">
            <div className="relative">
              {processing && (
                <div className="absolute inset-0 h-full w-full rounded-lg cursor-default bg-gray-500 bg-opacity-25 backdrop-blur-sm transition">
                  <div className="h-full flex justify-center items-center">
                    <svg
                      className={[
                        'text-gray-500 stroke-orange-500',
                        loaderClass,
                      ].join(' ')}
                      viewBox="0 0 38 38"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                          <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 18 18"
                              to="360 18 18"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                          </path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              )}
              <div>{children}</div>
            </div>
            <input
              type="file"
              name="document"
              className="hidden"
              data-cy="upload-file"
              onChange={async event => {
                setFieldValue('document', event.currentTarget.files[0]);

                if (event.currentTarget.files.length > 0) {
                  await submitForm();
                  setFieldValue('document', null);
                }
              }}
              disabled={processing}
            />
          </label>
        </Form>
      )}
    </Formik>
  );
}
