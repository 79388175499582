import { Lightning } from "../Lightning";
import { useTranslation } from "react-i18next";

export default function ExperienceGuaranteedDepartureIcon({ absolute = true }) {
  const { t } = useTranslation('experiences');

  return (
    <Lightning
      popover={t('guaranteedDeparture')}
      variant="guaranteedDeparture"
      absolute={absolute}
    />
  );
}
