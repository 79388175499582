import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { getFolder, updateFolderInfo } from '../../api/folders';
import { useNavigate, useParams } from 'react-router-dom';
import KoobContainer from '../../components/Koob/KoobContainer';
import KoobBackButton from '../../components/Koob/KoobBackButton';
import KoobTitle from '../../components/Koob/KoobTitle';
import KoobButton from '../../components/Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import FoldersBookingsList from './Partials/FoldersBookingsList';
import FoldersGuestsList from './Partials/FoldersGuestsList';
import FoldersDetailsCard from './Partials/FoldersDetailsCard';
import { useBooking, useExperienceBooking, useSnack, useTripDesigner } from '../../hooks';
import { SpinnerContainer } from 'containers/Hotels/HotelsDetail';
import { Spinner } from '@koob/margaret';
import { formatCurrency } from 'utils';
import { IcHistory, IcTrips } from 'components/icons';
import { bookingsTypeEnum } from 'utils/booking';
import KoobCard from 'components/Koob/KoobCard';
import { Button } from 'components';
import { Form, Formik } from 'formik';
import { camelCase } from 'lodash';
import * as Yup from 'yup';
import { TripHistory } from './Partials/TripHistory';

function BookingTypeButton({ type, label, bookingsType, setBookingsType, icon }) {
  return (
    <div
      className={[
        'flex items-center gap-2 mt-2 mr-1 py-2 px-3 text-base font-medium rounded-xl border hover:border-gray-500 border-gray-300 transition ease-in bg-white',
        bookingsType === type ? 'bg-gradient-to-b from-k-gd1 to-k-gd2 text-white' : 'text-gray-500'
      ].join(' ')}
      onClick={() => setBookingsType(type)}
      data-cy={`tab-${type}-booking`}
    >
      {icon}
      {label}
    </div>
  )
}

function PriceItem({ label, price, currency }) {
  if (!price) {
    return null;
  }

  return (
    <div className='flex justify-between items-center uppercase font-bold whitespace-nowrap'>
      <div className='text-xs'>{label}</div>
      <div className='text-lg text-orange-600'>
        {formatCurrency({ amount: price, currency })}
      </div>
    </div>
  )
}

function BookingRecap({ folder }) {
  const { totalPrice, totalExperiencePrice, totalHotelPrice, isTotalPrice, currency } = folder;
  const { t } = useTranslation('folders', { keyPrefix: 'bookings' });

  return (
    <div className='mb-2'>
      <KoobCard className={'my-2 py-1 h-50'}>
        <PriceItem className="my-2" label={t('hotelTitle')} price={totalHotelPrice} currency={currency} />
        <PriceItem className="my-2" label={t('experienceTitle')} price={totalExperiencePrice} currency={currency} />
        <PriceItem className="my-2" label={t('totalPrice')} price={totalPrice} currency={currency} />
      </KoobCard>
      {!isTotalPrice && (<div className='flex items-center text-red-600  space-x-1'>
        <i className="fa-light fa-circle-info text-xl" />
        <p className='text-base whitespace-nowrap'>
          {t('misMatchCurrency')}
        </p>
      </div>
      )}
    </div>
  )
}

export default function FoldersDetails() {
  const { t } = useTranslation('folders');
  const { folderId, isPending } = useParams();
  const navigate = useNavigate();
  const {setCustomerFile} = useBooking();
  const {setExperienceFilters} = useExperienceBooking();
  const {setTripFilters } = useTripDesigner();
  const [bookingsType, setBookingsType] = useState(bookingsTypeEnum.HOTEL);
  const [isAppromixateBirthdate, setIsAppromixateBirthdate] = useState(false);
  const { notify } = useSnack();
  const [editFolderField, setEditFolderField] = useState(false);
  const [isProposalTrip, setIsProposalTrip] = useState(false);

  const { data, reload, isLoading } = useAsync({
    promiseFn: getFolder,
    id: folderId,
    onResolve: (data) => {
      setTripFilters(prev=>({
        ...prev,
        clientFolder: data?.data
      }));
      const isProposal = data?.data?.trips.some(item => item.isProposal);
      setIsProposalTrip(isProposal);
      if (isProposal) {
        setBookingsType(bookingsTypeEnum.DRAFT);
      }
    }
  });

  const folder = data?.data ?? null;
  const travelers = isAppromixateBirthdate
    ? folder?.travelers?.filter(traveler => traveler?.ageIsExact === false)
    : folder?.travelers;
  const handleBookingStart = (goTo) => {
    setCustomerFile(folder);
    setExperienceFilters(current => ({
      ...current,
      clientFolder: folder,
    }));
    navigate(goTo);
  }
  const bookingList = folder?.bookings.filter(b => {
    if (bookingsType === bookingsTypeEnum.HOTEL) {
      return !!b.hotel;
    }
    if (bookingsType === bookingsTypeEnum.EXPERIENCE) {
      return !!b.experiencesBooked[0];
    }
  }) ?? []
  ;

  const tripData = folder?.trips.filter(b => {
    if (bookingsType === bookingsTypeEnum.TRIP) {
      return !b.draft;
    }
    if (bookingsType === bookingsTypeEnum.DRAFT || bookingsType === bookingsTypeEnum.HISTORY) {
      return !!b.draft;
    }
  }) ?? []
  ;

  const books = (bookingsType === bookingsTypeEnum.HOTEL || bookingsType === bookingsTypeEnum.EXPERIENCE) ? bookingList : tripData;
  const histories = tripData?.[0]?.tripHistory;

  const bookingsTabs = [
    ...(!isProposalTrip ? [{
      type: bookingsTypeEnum.HOTEL,
      label: t('bookings.hotelTitle'),
      icon: <i className="fa-regular fa-bed text-xl" />,
    },
    {
      type: bookingsTypeEnum.EXPERIENCE,
      label: t('bookings.experienceTitle'),
      icon: <i className="fa-regular fa-layer-group text-xl" />,
    },
    {
      type: bookingsTypeEnum.TRIP,
      label: t('bookings.tripTitle'),
      icon: <IcTrips className="h-5 w-5" />,
    }] : []),
    {
      type: bookingsTypeEnum.DRAFT,
      label: t('bookings.tripDraftTitle'),
      icon: <IcTrips className="h-5 w-5" />,
    },
    ...(isProposalTrip ? [{
      type: bookingsTypeEnum.HISTORY,
      label: t('bookings.tripHistory'),
      icon: <IcHistory className="h-5 w-5" />,
    }] : [])
  ].filter(Boolean);

  const updateFolder = async (values) => {
    try {
      await updateFolderInfo(folder?.id, values);
      notify(t('folderUpdatedSuccess'));
      setEditFolderField(false)
      reload();
    } catch (err) {
      const error = camelCase(err?.response?.data?.message ?? 'error');
      notify(t(`errors:${error}`), { type: 'error' });
    }
  }

  return (
    <KoobContainer>
      <div className="mb-5 flex justify-between">
        <div className="flex items-center space-x-5">
          <KoobBackButton />

          <KoobTitle>{t('details.screenTitle')}</KoobTitle>
        </div>

        <div className="flex space-x-3">
          <KoobButton
            onClick={() => handleBookingStart('/hotels')}
          >
            {t('bookings.addHotel')}
          </KoobButton>

          <KoobButton
            onClick={() => handleBookingStart('/experiences')}
          >
            {t('bookings.addExperience')}
          </KoobButton>
        </div>
      </div>

      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      {folder && (
        <>
          <div className="my-5">
            <KoobTitle size="text-2xl">
              {t('details.title')}
            </KoobTitle>

            <div className="mt-3">
              <Formik
                initialValues={{
                  name: folder?.name,
                  icr: folder?.icr
                }}
                enableReinitialize
                onSubmit={updateFolder}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required(t('errors:required')),
                  icr: Yup.string().required(t('errors:required')),
                })}
              >
                {({dirty, isSubmitting, isValid}) => (
                  <Form>
                    <FoldersDetailsCard
                      isPending={isPending}
                      folder={folder}
                      editFolderField={editFolderField}
                      setEditFolderField={setEditFolderField}
                    />
                    {(editFolderField) && (
                      <div className="flex justify-end">
                        <div className="flex items-center p-4">
                          <Button
                            disabled={!dirty || isSubmitting || !isValid || !editFolderField}
                            type="submit"
                            variant="primary"
                            style={{ minWidth: 120, minHeight: 40, marginLeft: 25 }}
                          >
                            {t('misc:saveChanges')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div className="my-5">
            <div className="flex justify-between">
              <KoobTitle size="text-2xl">
                {t('guests.title')}
              </KoobTitle>

              {folder?.travelers.length > 0 && (
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={isAppromixateBirthdate}
                    onChange={() =>
                      setIsAppromixateBirthdate(!isAppromixateBirthdate)
                    }
                  />
                  <p className="text-md">{t('approximateClients')}</p>
                </div>
              )}
            </div>

            <div className="mt-3 grid grid-cols-4 gap-5">
              <FoldersGuestsList
                isPending={isPending}
                reload={reload}
                travelers={travelers ?? []}
              />
            </div>
          </div>

          {!!folder.totalPrice && (
            <div className="my-5">
              <KoobTitle size="text-2xl">
                {t('bookings.priceSummary')}
              </KoobTitle>

              <div className='w-[24%]'>
                <BookingRecap folder={folder}/>
              </div>
            </div>
          )}

          <div className="my-5">
            <KoobTitle size="text-2xl">
              {t('bookings.title')}
            </KoobTitle>

            <div className="flex">
              {bookingsTabs.map(({ type, label, icon }, index) => (
                <BookingTypeButton
                  key={index}
                  type={type}
                  label={label}
                  bookingsType={bookingsType}
                  setBookingsType={setBookingsType}
                  icon={icon}
                />
              ))}
            </div>

            {bookingsType === bookingsTypeEnum.HISTORY ? (
              histories?.length && <TripHistory histories={histories}/>
            ) : (
              <div className="mt-3">
                <FoldersBookingsList
                  isPending={isPending}
                  bookings={books}
                  bookingsType={bookingsType}
                  travelers={folder?.travelers ?? []}
                  currency={folder?.currency}
                />
              </div>
              )
            }

          </div>
        </>
      )}
    </KoobContainer>
  )
}
