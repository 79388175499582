import ExperienceMarker from '../../../../components/Experience/ExperienceMarker';
import { MarkerClusterer, Polyline } from '@react-google-maps/api';
import MapHotelMarker from './MapHotelMarker';
import { useTripDesigner } from '../../../../hooks';
import generateMarker from '@koob/map-marker';
import ComposeMapItineraryPoiMarker from './ComposeMapItineraryPoiMarker';
import ComposeMapItineraryProgramMarker from './ComposeMapItineraryProgramMarker';

export default function ComposeMapItinerary({ days, showHotels = true }) {
  const { itineraryPinLevel } = useTripDesigner();

  const linePoints = days
    ?.reduce((acc, d) => {
      const experience = d?.experience;

      if (
        experience
        && !acc.find((p) => p.id === experience.id)
      ) {
        const start = {
          id: experience.id,
          lat: parseFloat(experience.startPosition?.lat),
          lng: parseFloat(experience.startPosition?.lon),
        };

        acc.push(start);
      }

      return acc;
    }, [])
    .filter((p) => p && p.lat && p.lng);

  const experiences = days?.reduce((acc, d) => {
    const experience = d?.experience;

    if (
      experience
      && !acc.find((e) => e?.id === experience?.id)
    ) {
      acc.push(experience);
    }
    return acc;
  }, []);

  const hotels = days?.reduce((acc, d) => {
    const hotel = d?.hotel;

    if (
      hotel
      && !acc.find((h) => h.id === hotel?.id)
    ) {
      acc.push(hotel);
    }
    return acc;
  }, []);

  const programs = days?.reduce((acc, d) => {
    d?.experience?.programs?.forEach((p) => {
      if (!acc.find((ap) => ap.id === p.id)) {
        acc.push(p);
      }
    });
    return acc;
  }, []);

  return (
    <>
      <Polyline
        path={linePoints}
        options={{
          strokeColor: '#7c3aed',
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
      />

      {experiences?.map((e, index) => (
        <ExperienceMarker
          key={'map-i-s-' + e?.id + index}
          experience={e}
          mode="itinerary"
          isForTrip={true}
          lat={e?.startPosition?.lat}
          lng={e?.startPosition?.lon}
          typeOverride={index === 0 ? 'start' : index === experiences.length - 1 ? 'flag' : null}
        />
      ))}

      {showHotels && hotels?.map((h, index) => (
        <MapHotelMarker
          key={'map-i-h-' + h?.id + index}
          hotel={h}
          addHotel={() => {}}
          kind={'itinerary'}
        />
      ))}

      <MarkerClusterer>
        {(clusterer) => (
          <>
            {itineraryPinLevel >= 1 && (
              programs?.map((p, i) => (
                <>
                  <ComposeMapItineraryProgramMarker
                    key={'map-i-p-' + p.id + i}
                    label={`${i + 1}`}
                    program={p}
                    icon={generateMarker({type: 'programDay', mainColor: '#9C2276', fillColor: '#FFFFFF'})}
                    clusterer={clusterer}
                  />

                  {itineraryPinLevel >= 2 && (
                    p.pois?.map((poi, j) => (
                      <ComposeMapItineraryPoiMarker
                        key={'map-i-poi-' + poi.id + j}
                        label={`${i + 1}`}
                        poi={poi}
                        icon={generateMarker({type: 'poi', mainColor: '#D7519C', fillColor: '#FFFFFF'})}
                        clusterer={clusterer}
                      />
                    ))
                  )}
                </>
              ))
            )}
          </>
        )}
      </MarkerClusterer>
    </>
  );
}
