import { useMemo, useState } from "react";
import { ExperienceAvailabilityTypeEnum } from "@koob/enums";
import moment from "moment";
import { cva } from "class-variance-authority";

const AVAILABILITY_PRIORITY = {
  [ExperienceAvailabilityTypeEnum.FREE_SALE]: 1,
  [ExperienceAvailabilityTypeEnum.PRE_CONFIRMED]: 2,
  [ExperienceAvailabilityTypeEnum.ON_REQUEST]: 3,
  [ExperienceAvailabilityTypeEnum.CLOSED]: 4
};

function CalendarNavigateButton({ onClick, disabled, children }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`h-14 w-14 border-none ${disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"}`}
    >
      {children}
    </button>
  );
}

const day = cva([
  'aspect-square flex items-center justify-center rounded-full font-medium text-sm',
  'transition-all duration-200',
], {
  variants: {
    availability: {
      [ExperienceAvailabilityTypeEnum.FREE_SALE]: 'bg-green-500',
      [ExperienceAvailabilityTypeEnum.ON_REQUEST]: 'bg-yellow-500',
      [ExperienceAvailabilityTypeEnum.CLOSED]: 'bg-red-500',
      [ExperienceAvailabilityTypeEnum.PRE_CONFIRMED]: 'bg-purple-500',
    },
    hovered: {
      true: 'ring-2 ring-offset-2 ring-gray-500'
    },
    selected: {
      true: 'ring-2 ring-offset-2 ring-k-orange'
    },
    selectable: {
      true: 'cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-gray-500',
      false: 'bg-gray-100/50 text-white/30 cursor-not-allowed'
    },
    currentMonth: {
      true: 'text-white/90',
      false: 'opacity-50'
    }
  }
});

export default function ExperienceAvailabilityCalendar({ selectedDates, setSelectedDates, defaultDate, minDate, maxDate, duration, getDayAvailability }) {
  const [currentDate, setCurrentDate] = useState(defaultDate ?? new Date());
  const [hoverDate, setHoverDate] = useState(null);

  const getDaysInMonth = (date) => {
    return moment(date).daysInMonth();
  };

  const getFirstDayOfMonth = (date) => {
    const day = moment(date).startOf('month').day();
    return day === 0 ? 6 : day - 1;
  };

  const isDateSelected = (date) => {
    if (!selectedDates) return false;

    return moment(date).isBetween(
      moment(selectedDates[0], 'YYYY-MM-DD'),
      moment(selectedDates[1], 'YYYY-MM-DD'), null, '[]'
    );
  };

  const isDateInRange = (date) => {
    if (!hoverDate) return false;
    const endDate = moment(hoverDate).add(duration - 1, 'days');
    return moment(date).isBetween(hoverDate, endDate, 'day', '[]');
  };

  const isDateAvailable = (startDate) => {
    if (!startDate) return false;
    const endDate = moment(startDate).add(duration - 1, 'days');

    let currentDate = moment(startDate);
    while (currentDate.isSameOrBefore(endDate, 'day')) {
      if (getDayAvailability(currentDate.toDate(), true) === ExperienceAvailabilityTypeEnum.CLOSED) {
        return false;
      }
      currentDate.add(1, 'day');
    }
    return true;
  };

  const isDateValid = (date) => {
    if (minDate && moment(date).isBefore(moment(minDate).startOf('day'))) return false;
    if (maxDate && moment(date).isAfter(moment(maxDate).endOf('day'))) return false;
    return isDateAvailable(date);
  };

  const getMostRestrictiveAvailability = (startDate) => {
    if (!startDate) return null;

    let mostRestrictive = ExperienceAvailabilityTypeEnum.FREE_SALE;
    const endDate = moment(startDate).add(duration - 1, 'days');
    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate, 'day')) {
      const availability = getDayAvailability(currentDate.toDate(), true);
      if (AVAILABILITY_PRIORITY[availability] > AVAILABILITY_PRIORITY[mostRestrictive]) {
        mostRestrictive = availability;
      }
      if (mostRestrictive === ExperienceAvailabilityTypeEnum.CLOSED) break;
      currentDate.add(1, 'day');
    }
    return mostRestrictive;
  };

  const getDayArray = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDay = getFirstDayOfMonth(currentDate);

    const prevMonth = moment(currentDate).subtract(1, 'month');
    const prevMonthDays = prevMonth.daysInMonth();

    let days = [];

    // Previous month days
    for (let i = firstDay - 1; i >= 0; i--) {
      const date = moment(currentDate).subtract(1, 'month').date(prevMonthDays - i).toDate();
      days.push({
        day: prevMonthDays - i,
        currentMonth: false,
        date,
        valid: isDateValid(date),
        availability: getDayAvailability(date)
      });
    }

    // Current month days
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment(currentDate).date(i).toDate();
      days.push({
        day: i,
        currentMonth: moment(date).isSameOrAfter(moment(), 'day'), // Disable past days
        date,
        valid: isDateValid(date),
        availability: getDayAvailability(date)
      });
    }

    // Next month days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      const date = moment(currentDate).add(1, 'month').date(i).toDate();
      days.push({
        day: i,
        currentMonth: false,
        date,
        valid: isDateValid(date),
        availability: getDayAvailability(date)
      });
    }

    return days;
  };

  const changeMonth = (increment) => {
    setCurrentDate(moment(currentDate).add(increment, 'months').toDate());
    setHoverDate(null);
  };

  const changeYear = (increment) => {
    setCurrentDate(moment(currentDate).add(increment, 'years').toDate());
    setHoverDate(null);
  };

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  const dayNames = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  const canNavigateBack = useMemo(() => {
    if (!minDate) return true;
    const firstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    return firstOfMonth > minDate;
  }, [currentDate, minDate]);

  const canNavigateForward = useMemo(() => {
    if (!maxDate) return true;
    const lastOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return lastOfMonth < maxDate;
  }, [currentDate, maxDate]);

  return (
    <div className="w-full max-w-sm">
      <div className="flex items-center justify-between mb-4">
        <div className="flex space-x-2">
          <CalendarNavigateButton
            onClick={() => changeYear(-1)}
            disabled={!canNavigateBack}
          >
            <i className="far fa-chevrons-left" />
          </CalendarNavigateButton>

          <CalendarNavigateButton
            onClick={() => changeMonth(-1)}
            disabled={!canNavigateBack}
          >
            <i className="far fa-chevron-left" />
          </CalendarNavigateButton>
        </div>

        <span className="font-medium">
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </span>

        <div className="flex space-x-2">
          <CalendarNavigateButton
            onClick={() => changeMonth(1)}
            disabled={!canNavigateForward}
          >
            <i className="far fa-chevron-right" />
          </CalendarNavigateButton>

          <CalendarNavigateButton
            onClick={() => changeYear(1)}
            disabled={!canNavigateForward}
          >
            <i className="far fa-chevrons-right" />
          </CalendarNavigateButton>
        </div>
      </div>

      <div className="px-5">
        <div className="grid grid-cols-7 gap-1 mb-2">
          {dayNames.map(day => (
            <div key={day} className="text-center text-sm font-medium text-gray-600">
              {day}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-3">
          {getDayArray().map((dateObj, index) => {
            const isInHoveredRange = isDateInRange(dateObj.date);
            const isSelected = isDateSelected(dateObj.date);
            const availability = isInHoveredRange || isSelected
              ? getMostRestrictiveAvailability(isSelected ? selectedDates[0] : hoverDate)
              : dateObj.availability;
            const isSelectable = dateObj.valid && dateObj.availability !== ExperienceAvailabilityTypeEnum.CLOSED;

            return (
              <div
                key={index}
                className={day({
                  availability: availability,
                  hovered: isInHoveredRange,
                  selected: isSelected,
                  selectable: isSelectable,
                  currentMonth: dateObj.currentMonth
                })}
                onClick={() => {
                  if (isSelectable) {
                    if (!isSelected) {
                      setSelectedDates([
                        dateObj.date,
                        moment(dateObj.date).add(duration - 1, 'days').toDate()
                      ]);
                    } else {
                      setSelectedDates(null);
                    }
                  }
                }}
                onMouseEnter={() => isSelectable && setHoverDate(dateObj.date)}
                onMouseLeave={() => setHoverDate(null)}
              >
                {dateObj.day}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
