import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import { Stack } from '@koob/margaret';
import Button from 'components/Button';

const getTimeSeconds = date =>
  Math.floor((date ? new Date(date) : new Date()).getTime() / 1000);

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, []);
};

const BookingCountdown = ({expiryDate, resetTunnel}) => {
  const [seconds, setSeconds] = useState(getTimeSeconds());

  const handleRefreshTime = () => {
    const currentTime = getTimeSeconds();
    if (seconds !== currentTime) {
      setSeconds(currentTime);
    }
  };

  useInterval(() => {
    handleRefreshTime();
  }, 1000);

  const secondsLeft = Math.max(getTimeSeconds(parseISO(expiryDate)) - seconds, 0);
  const {t} = useTranslation('booking', {keyPrefix: 'form'});

  return (
    <div className="text-center">
      <div className="text-gray-500 font-medium">
        Booking expires in
      </div>

      <div
        className={[
          'tracking-wider',
          secondsLeft <= 300 ? 'text-5xl text-red-500 font-semibold animate-pulse' : 'text-4xl font-medium',
        ].join(' ')}
      >
        {format(secondsLeft * 1000, 'mm:ss')}
      </div>

      {secondsLeft <= 0 && (
        <Modal
          isOpen={true} onClose={() => resetTunnel()} title={t('expireTitle')}
          footer={
            <Stack alignX="flex-end" gutterSize={1}>
              <Button
                onClick={() => {
                  resetTunnel();
                }} variant="primary"
              >
                {t('misc:ok')}
              </Button>
            </Stack>
          }
        >
          {t('expireMessage')}
        </Modal>
      )}
    </div>
  );
};

export default BookingCountdown;
