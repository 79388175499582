import { useTranslation } from "react-i18next";
import React from "react";

export default function ExperienceAvailabilityLegend() {
  const { t } = useTranslation('experiences');

  return (
    <div className="flex flex-wrap gap-x-3 gap-y-1">
      {[
        {
          color: 'bg-red-600',
          label: t('state.closed')
        },
        {
          color: 'bg-yellow-500',
          label: t('state.on_request')
        },
        {
          color: 'bg-green-500',
          label: t('state.free_sale')
        },
        {
          color: 'bg-indigo-600',
          label: t('state.pre_confirmed')
        }
      ].map((item) => (
        <div
          key={item.label}
          className="flex items-center space-x-2"
        >
          <div
            className={[
              item.color,
              'h-4 w-4 rounded-full'
            ].join(' ')}
          />
          <div>
            {item.label}
          </div>
        </div>
      ))}
    </div>
  )
}
