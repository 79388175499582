import KoobTitle from "../Koob/KoobTitle";
import { KoobModal } from "@koob/ui";
import { useTranslation } from "react-i18next";
import ExperienceAvailability from "./ExperienceAvailability";

export default function ExperienceAvailabilityModal({ open, setOpen, experienceId, onSelect, minDate, maxDate, duration }) {
  const { t } = useTranslation('experiences', { keyPrefix: 'detail' });

  return (
    <KoobModal
      open={open}
      setOpen={setOpen}
      widthClass="sm:max-w-xl"
    >
      <KoobTitle className="text-center">
        {t('availabilityModalTitle')}
      </KoobTitle>

      <div className="p-6">
        <ExperienceAvailability
          experienceId={experienceId}
          onSelect={onSelect}
          minDate={minDate}
          maxDate={maxDate}
          duration={duration}
        />
      </div>
    </KoobModal>
  )
}
