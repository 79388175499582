import React from 'react';
import KoobEmptyState from 'components/Koob/KoobEmptyState';
import KoobPlaceholder from 'components/Koob/KoobPlaceholder';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from 'utils';
import BookingStatusBadge from 'components/Hotel/Booking/Edit/BookingStatusBadge';
import KoobTableSortable from 'components/Koob/KoobTableSortable';
import FoldersBookingEditButton from './FoldersBookingEditButton';
import ExperienceTypeIcon from '../../Experiences/Partials/ExperienceTypeIcon';
import { bookingsTypeEnum } from 'utils/booking';
import FoldersTripDraftEditButton from './FoldersTripDraftEditButton';
import { ActionLink, ActionsLinks } from '../../../ui';
import { Tooltip } from '../../../components';
import ExperienceGuaranteedDepartureIcon from "../../../components/Experience/ExperienceGuaranteedDepartureIcon";

const BookingPrice = ({ price, currency }) => {
  if (price === undefined || price === null) {
    return null;
  }
  return (
    <div className="text-xs text-gray-600 font-extrabold">
      {formatCurrency({
        amount: price,
        currency: currency,
      })}
    </div>
  )
}

function InternalToReferenceTooltip() {
  const { t } = useTranslation('folders', { keyPrefix: 'bookings' });

  return (
    <Tooltip tip={t('internalToReference')}>
      <i className="far fa-info-circle" />
    </Tooltip>
  );
}

export default function FoldersBookingsList({ isPending, bookings, bookingsType, travelers, currency }) {
  const { t } = useTranslation('folders', { keyPrefix: 'bookings' });
  const hasCancellationFess = bookings?.some(booking => booking?.cancellationFees)

  const hotelColumns = React.useMemo(
    () => [
      { label: t('traveler'), accessor: 'traveler' },
      { label: t('hotel'), accessor: 'hotel' },
      { label: t('city'), accessor: 'city' },
      { label: t('tir'), accessor: 'tir', customContent: <InternalToReferenceTooltip /> },
      { label: t('createdAt'), accessor: 'createdAt' },
      { label: t('startAt'), accessor: 'startAt' },
      { label: t('endAt'), accessor: 'endAt' },
      { label: t('provider'), accessor: 'provider' },
      { label: t('status.title'), accessor: 'status' },
      ...(
        hasCancellationFess ? [{ label: t('cancellationFees'), accessor: 'cancellationFees', cannotBeReordered: true }] : []
      ),
      { label: t('amount'), accessor: 'price' },
      { label: '', accessor: 'editButton' }
    ], []
  );


  const experienceColumns = React.useMemo(
    () => [
      { label: t('traveler'), accessor: 'traveler' },
      { label: t('name'), accessor: 'name' },
      { label: t('type'), accessor: 'type' },
      { label: t('city'), accessor: 'city' },
      { label: t('tir'), accessor: 'tir', customContent: <InternalToReferenceTooltip /> },
      { label: t('createdAt'), accessor: 'createdAt' },
      { label: t('startAt'), accessor: 'startAt' },
      { label: t('endAt'), accessor: 'endAt' },
      { label: t('provider'), accessor: 'provider' },
      { label: t('status.title'), accessor: 'status' },
      { label: t('guaranteedDeparture'), accessor: 'guaranteedDeparture' },
      { label: t('amount'), accessor: 'price' },
      { label: '', accessor: 'editButton' }
    ], []
  );

  const tripColumns = React.useMemo(
    () => [
      { label: t('id'), accessor: 'id' },
      { label: t('traveler'), accessor: 'traveler' },
      { label: t('tir'), accessor: 'tir', customContent: <InternalToReferenceTooltip /> },
      { label: t('name'), accessor: 'name' },
      { label: t('startLocation'), accessor: 'startLocation' },
      { label: t('endLocation'), accessor: 'endLocation' },
      { label: t('createdAt'), accessor: 'createdAt' },
      { label: t('startAt'), accessor: 'startAt' },
      { label: t('endAt'), accessor: 'endAt' },
      { label: t('provider'), accessor: 'provider' },
      { label: t('status.title'), accessor: 'status' },
      { label: t('amount'), accessor: 'price' },
      { label: '', accessor: 'editButton', overflow: 'visible' }
    ], []
  );

  const rows = React.useMemo(() => {
    return bookings.map((booking, index) => {
      if (bookingsType === bookingsTypeEnum.HOTEL) {
        return {
          traveler: (
            booking.roomsBooked && booking.roomsBooked[0]?.travelers
          ) && `${booking.roomsBooked[0]?.travelers[0]?.firstName} ${booking.roomsBooked[0]?.travelers[0]?.lastName}`,
          hotel: booking.hotel?.displayName,
          city: booking.hotel?.city?.title ?? booking?.city?.title,
          tir: booking.toReference,
          createdAt: formatDate(booking.createdAt, 'dd/MM/yyyy'),
          startAt: formatDate(booking.startAt, 'dd/MM/yyyy'),
          endAt: formatDate(booking.endAt, 'dd/MM/yyyy'),
          provider: booking.kind === 'dmc' ? booking.hotel.organization.displayName : booking.kind,
          status: <BookingStatusBadge status={booking.state}/>,
          ...(
            hasCancellationFess && { cancellationFees: <BookingPrice price={booking.cancellationFees} currency={currency}/>, }
          ),
          price: Boolean(booking?.sourceTotalPrice) && <BookingPrice price={booking?.sourceTotalPrice} currency={currency}/>,
          editButton: (
            <ActionsLinks data-cy={`folder-booking-${index}`}>
              <FoldersBookingEditButton bookingId={booking.id}/>
            </ActionsLinks>
          )
        };
      }

      if (bookingsType === bookingsTypeEnum.EXPERIENCE) {
        return {
          traveler: (
            booking?.experiencesBooked && booking.experiencesBooked[0]?.travelers
          ) && `${booking?.experiencesBooked[0]?.travelers[0]?.firstName} ${booking?.experiencesBooked[0]?.travelers[0]?.lastName}`,
          name: booking?.experiencesBooked[0].experience?.name,
          tir: booking.toReference,
          type: <ExperienceTypeIcon
            type={booking?.experiencesBooked[0].experience?.type} config={booking?.experiencesBooked[0].experience?.transfer?.type}
          />,
          city: booking?.experiencesBooked[0].experience?.inCity?.title,
          createdAt: formatDate(booking.createdAt, 'dd/MM/yyyy'),
          startAt: formatDate(booking.startAt, 'dd/MM/yyyy'),
          endAt: formatDate(booking.endAt, 'dd/MM/yyyy'),
          provider: booking.experiencesBooked[0].experience.organizationName,
          status: <BookingStatusBadge status={booking.state}/>,
          guaranteedDeparture: booking?.guaranteedDeparture ? (<ExperienceGuaranteedDepartureIcon/>) : null,
          price: Boolean(booking?.sourceTotalPrice !== undefined) && <BookingPrice price={booking?.sourceTotalPrice} currency={currency} />,
          editButton: (
            <ActionsLinks>
              {!booking.trip && (
                <ActionLink
                  to={`/bookings/${booking.id}/experience-export`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa-regular fa-eye text-lg"/>
                </ActionLink>
              )}

              <FoldersBookingEditButton bookingId={booking.id}/>
            </ActionsLinks>
          )
        };
      }

      if (bookingsType === bookingsTypeEnum.TRIP || bookingsType === bookingsTypeEnum.DRAFT) {
        const traveler = travelers.length > 0 ? `${travelers[0]?.firstName} ${travelers[0]?.lastName}` : '';

        return {
          id: booking?.id,
          traveler: traveler,
          startLocation: booking?.endLocation?.title,
          endLocation: booking?.endLocation?.title,
          tir: booking.toReference,
          provider: booking?.dmc?.displayName,
          name: booking.name,
          city: booking.startLocation.title,
          createdAt: formatDate(booking.createdAt, 'dd/MM/yyyy'),
          startAt: formatDate(booking.startDate, 'dd/MM/yyyy'),
          endAt: formatDate(booking.endDate, 'dd/MM/yyyy'),
          status: <BookingStatusBadge status={booking.draft ? 'draft' : 'booked'}/>,
          price: Boolean(booking?.draftPrice?.totalPrice) && <BookingPrice
            price={booking?.draftPrice?.totalPrice} currency={booking?.draftPrice?.currency}
          />,
          editButton: (
            <ActionsLinks>
              {booking.isProposal ? (
                <Tooltip
                  tip={t('proposalDisabled')}
                  position="left"
                >
                  <i className="fa-regular fa-dollar-sign text-lg"/>
                </Tooltip>
              ) : (
                <ActionLink
                  to={`/trips/${booking.id}/receipt`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa-regular fa-dollar-sign text-lg"/>
                </ActionLink>
              )}

              {bookingsType === bookingsTypeEnum.DRAFT && (
                <FoldersTripDraftEditButton tripId={booking.id}/>
              )}

              {bookingsType === bookingsTypeEnum.TRIP && (
                <ActionLink
                  to={`/trips/${booking.id}/chat`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa-regular fa-messages text-lg"/>
                </ActionLink>
              )}

              {(
                bookingsType === bookingsTypeEnum.TRIP || bookingsType === bookingsTypeEnum.DRAFT
              ) && (
                <ActionLink
                  to={`/trips/${booking.id}/export`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <i className="fa-regular fa-eye text-lg"/>
                </ActionLink>
              )}
            </ActionsLinks>
          )
        };
      }
    });
  }, [bookings]);

  const currentColumns = {
    hotel: hotelColumns,
    experience: experienceColumns,
    trip: tripColumns,
    draft: tripColumns
  };

  return (
    !isPending ? (
      <>
        {bookings?.length > 0 ? (
          <KoobTableSortable
            columns={currentColumns[bookingsType]}
            data={rows}
          />
        ) : (
          <KoobEmptyState
            title={t('emptyState.title')}
            description={t('emptyState.description')}
          />
        )}
      </>
    ) : (
      <>
        <KoobPlaceholder className="mb-1 h-10"/>

        <div className="grid grid-cols-1 gap-1">
          {[...Array(4)].map((_e, key) => (
            <KoobPlaceholder className="h-16" key={key}/>
          ))}
        </div>
      </>
    )
  );
}
