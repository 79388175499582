import { useTranslation } from "react-i18next";
import { cva } from "class-variance-authority";

const icon = cva([
  'fas rounded-full h-12 w-12 text-white text-xl flex justify-center items-center shrink-0',
], {
  variants: {
    state: {
      confirmed: 'fa-check bg-green-500',
      pre_confirmed: 'fa-bus bg-purple-500',
      sent: 'fa-paper-plane bg-orange-500',
    }
  }
});

export default function ExperiencesBasketState({ state }) {
  const { t } = useTranslation('booking');

  return (
    <div className="mb-5 flex justify-center space-x-5 items-start py-3 max-w-xl mx-auto">
      <i className={icon({ state })} />

      <div className="leading-none">
        <div className="font-medium text-lg text-gray-900">
          {t(`experienceState.${state}.title`)}
        </div>
        <div className="text-gray-600">
          {t(`experienceState.${state}.description`)}
        </div>
      </div>
    </div>
  )
}
